
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ExchangeBalance, ExchangeTransaction } from "api/index";
import { mdiPlus } from "@mdi/js";
import TransactionForm from "@/components/Transactions/TransactionForm.vue";
import { ExchangeLogo } from "@/service/exchangeLogo";
@Component({
  components: { TransactionForm },
})
export default class Transactions extends Vue {
  @Prop()
  exchangeTransactions?: Array<ExchangeTransaction>;

  @Prop()
  exchangeBalances?: Array<ExchangeBalance>;

  headers = [
    { text: "Date", value: "date" },
    { text: "From", value: "fromBalance.exchange" },
    { text: "To", value: "toBalance.exchange" },
    { text: "Amount from", value: "amountInFromCurrency" },
    { text: "Amount to", value: "amountInToCurrency" },
    { text: "Currency rate", value: "currencyRate" },
    { text: "Type", value: "type" },
  ];

  dialog = false;

  icons = {
    mdiPlus: mdiPlus,
  };

  ExchangeLogo = ExchangeLogo;
}
