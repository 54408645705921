
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Exchange, ExchangeBalance, ExchangeTransaction } from "api/models";
import { ExchangeLogo } from "@/service/exchangeLogo";

@Component
export default class TransactionForm extends Vue {
  @Prop()
  exchangeBalances?: Array<ExchangeBalance>;

  exchangeTransaction: ExchangeTransaction = {};

  exchanges: Array<Exchange> = [];

  created() {
    const exchanges = {};
    for (const exchangeBalance of this.exchangeBalances || []) {
      if (exchangeBalance.exchange) {
        exchanges[exchangeBalance.exchange.id] = exchangeBalance.exchange;
      }
    }
    this.exchanges = Object.values(exchanges);
  }

  ExchangeLogo = ExchangeLogo;
}
