
import Vue from "vue";
import Component from "vue-class-component";
import { ExchangeBalance, ExchangeTransaction } from "next-trader-api";
import { exchangeBalanceApi, exchangeTransactionApi } from "@/plugins/api";
import Transactions from "@/components/Transactions/Transactions.vue";

@Component({
  components: { Transactions },
})
export default class TransactionsParent extends Vue {
  exchangeBalances: Array<ExchangeBalance> = [];
  exchangeTransactions: Array<ExchangeTransaction> = [];

  created() {
    exchangeBalanceApi.getAppExchangebalanceGetall().subscribe((next) => {
      this.exchangeBalances = next;
    });

    exchangeTransactionApi.getAppExchangetransactionGetall().subscribe((next) => {
      this.exchangeTransactions = next;
    });
  }
}
